import { createContext, useContext } from "react"

export type ImageViewContextType = {
  onImageClick: (src: string) => void
}

export const ImageViewContext = createContext({} as ImageViewContextType)

export const useImageViewContext = () => {
  const context = useContext(ImageViewContext)
  if (!context) {
    throw new Error(
      "useImageViewContext must be used within an ImageViewProvider",
    )
  }
  return context
}
