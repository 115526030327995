import { Tag } from "@blueprintjs/core"
import React, { useContext } from "react"
import { useAppSelector } from "../../reducers/hooks"
import {
  hasMessageFileBeenShared,
  selectActiveChat,
  selectMessageChat,
} from "../../reducers/chatsSliceSelectors"
import { Message } from "../../reducers/chatsSlice"
import { XmppContext } from "../../stream/xmppClient"

export const FileToolbar = ({
  handleDownload,
  url,
  downloadFileName,
  handleShareLink,
  message,
  isMobile,
}: {
  handleDownload: (url: string, fileName: string) => void
  url: string
  downloadFileName: string
  handleShareLink: (url: string) => void
  message: Message
  isMobile: boolean
}) => {
  const activeChat = useAppSelector(selectActiveChat)
  const { myJid } = useContext(XmppContext)
  const originalMessageChat = useAppSelector(
    selectMessageChat(message, myJid.toString()),
  )
  const hasFileBeenShared =
    hasMessageFileBeenShared(activeChat, message.id) ||
    (originalMessageChat &&
      hasMessageFileBeenShared(originalMessageChat, message.id))

  return (
    <div className="absolute bottom-2 right-2 flex flex-row gap-2">
      {isMobile ? (
        <a href={url} download target="_blank" rel="noreferrer">
          <Tag icon="download" round intent="primary" large />
        </a>
      ) : (
        <a
          href={url}
          download
          className="invisible group-hover:visible"
          onClick={(e) => {
            e.preventDefault()
            handleDownload(url, downloadFileName)
          }}
        >
          <Tag icon="download" round intent="primary" large />
        </a>
      )}

      <a
        href="#"
        title={hasFileBeenShared ? "File has been shared" : "Share file"}
        onClick={(e) => {
          e.preventDefault()
          handleShareLink(downloadFileName)
        }}
      >
        <Tag rightIcon="link" round intent="primary" large>
          <div className="flex flex-row gap-x-1">
            {hasFileBeenShared && (
              <img className="h-4 mt-0.5" src="/group.svg" />
            )}
            <span>Share</span>
          </div>
        </Tag>
      </a>
    </div>
  )
}
