import { createContext, useContext } from "react"
import { Message } from "../../reducers/chatsSlice"

export type PDFViewerContextType = {
  onPDFClick: (message: Message) => void
}

export const PDFViewerContext = createContext({} as PDFViewerContextType)

export const usePDFViewerContext = () => {
  const context = useContext(PDFViewerContext)
  if (!context) {
    throw new Error(
      "usePDFViewerContext must be used within an PDFViewerProvider",
    )
  }
  return context
}
