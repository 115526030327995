import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  memo,
} from "react"
import { Button } from "@blueprintjs/core"
import RouteStopView from "./RouteStopView"
import css from "./RouteStopList.module.css"
import { DriverContext } from "../contexts/DriverContext"
import {
  findCurrentTarget,
  isStopActive,
  RouteStop,
} from "../reducers/routes2gSlice"
import { Plus } from "lucide-react"

type RouteStopListProps = {
  routeStops?: RouteStop[]
}

const RouteStopList = ({ routeStops }: RouteStopListProps) => {
  const [isAdding, setIsAdding] = useState(false)
  const [stops, setStops] = useState<RouteStop[]>([])

  const { upsertRoute2g, driver } = useContext(DriverContext)

  useEffect(() => {
    if (routeStops) {
      const updatedStops = routeStops.map((stop) => {
        const isCurrentTarget = findCurrentTarget(routeStops) === stop
        return {
          ...stop,
          is_active: isStopActive(stop),
          is_current_target: isCurrentTarget,
        }
      })
      setStops(updatedStops)
    } else {
      setStops([])
    }
  }, [routeStops])

  const updateStop = useCallback(
    (stop: RouteStop, index: number) => {
      const updated = stops
        .map((s, idx) => (idx === index ? stop : s))
        .filter((s) => s.location.formattedAddress)
      setStops(updated)
      setIsAdding(false)
      if (!driver) {
        return
      }
      upsertRoute2g({ stops: updated, jid: driver.jid }, driver)
      if (stop.id == undefined) {
        window.analytics.track("AddRouteStop", { source: "list" })
      } else {
        window.analytics.track("UpdateRouteStop", { id: stop.id })
      }
    },
    [stops, driver, upsertRoute2g],
  )

  const removeStop = useCallback(
    (index: number) => {
      const updated = stops
        .filter((_, idx) => idx !== index)
        .filter((s) => s.location.formattedAddress)
      setStops(updated)
      setIsAdding(false)
      if (!driver) {
        return
      }
      upsertRoute2g({ stops: updated, jid: driver.jid }, driver)
      window.analytics.track("RemoveRouteStop", { id: stops[index]?.id })
    },
    [stops, driver, upsertRoute2g],
  )

  const moveStop = useCallback(
    (from: number, to: number) => {
      const updatedStops = [...stops]
      const [movedStop] = updatedStops.splice(from, 1)
      updatedStops.splice(to, 0, movedStop)
      setStops(updatedStops)
    },
    [stops],
  )

  const upsertStops = useCallback(() => {
    if (isAdding) {
      return
    }
    if (!driver) {
      return
    }
    upsertRoute2g({ stops: stops, jid: driver.jid }, driver)
  }, [isAdding, driver, stops, upsertRoute2g])

  const addNewStop = useCallback(() => {
    setStops([...stops, { location: { formattedAddress: "" } }])
    setIsAdding(true)
  }, [stops])

  return (
    <div className="m-1">
      {stops.map((stop, index) => (
        <div key={index} className={css.timelineContainer}>
          <RouteStopView
            index={index}
            stop={stop}
            onUpdate={updateStop}
            onRemove={removeStop}
            onMove={moveStop}
            onMoveCompleted={upsertStops}
          />
          {index < stops.length - 1 && (
            <div className={css.timelineConnector}></div>
          )}
        </div>
      ))}
      {!isAdding && (
        <div
          className="flex gap-4 items-center ml-[34px] mt-2 cursor-pointer w-52"
          onClick={addNewStop}
        >
          <Button minimal={true}>
            <Plus size={16} />
          </Button>
          <div className="font-bold text-s text-gray-500 pl-1">
            Add new destination
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(RouteStopList)
