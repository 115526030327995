import React, { useContext, useMemo } from "react"
import { ChatEvent, MessageDisplayType } from "../reducers/chatsSlice"
import { XmppContext } from "../stream/xmppClient"
import ChatHelpers from "../lib/chatHelpers"
import { ReadStatus } from "./message/ReadStatus"
import classNames from "classnames"
import AvatarImage, { participantPhotoToSrc } from "./AvatarImage"
import RelativeTimeLabel from "./RelativeTimeLabel"
import { useAppSelector } from "../reducers/hooks"
import {
  selectDriverParticipant,
  selectChatCard,
  selectChat,
  CardChat,
  ChatWithMessages,
  selectFilteredChatsAndMessages,
} from "../reducers/chatsSliceSelectors"
import css from "./ChatCard.module.css"
import { findRoute } from "../reducers/routesSlice"
import { findActivity } from "../reducers/activitySlice"
import { messageAuthorName } from "../reducers/profilesSliceSelectors"
import MessageHelpers from "../lib/messageHelpers"
import { messageStatuses } from "../reducers/selectors"
import { RetractedMessagePlaceholder } from "./message/MessageBubleBody"
import { shallowEqual } from "react-redux"
import ChatListUnreadCount from "./ChatListUnreadCount"
import RouteInfo from "./RouteInfo"
import {
  findRoute2g,
  MULTIPLE_DESTINATIONS_FLAG,
} from "../reducers/routes2gSlice"
import { useFeatureFlagEnabled } from "posthog-js/react"

type ChatCardProps = {
  chatJid: string
  chatName: string
  chatActive: boolean
  chatColor: string | undefined
  onClick: () => void
  compact?: boolean
  failedSync?: boolean
  className?: string
}

const LastMessageTimestamp = ({
  chatJid,
  lastChatEvent,
}: {
  chatJid: string
  lastChatEvent: ChatEvent
}) => {
  const { myJid } = useContext(XmppContext)
  const chats: ChatWithMessages[] = useAppSelector(
    selectFilteredChatsAndMessages([chatJid]),
  )

  const unreadCount = useMemo(() => {
    return chats.reduce(
      (acc, curr) =>
        acc + ChatHelpers.unreadMessagesCount(curr, myJid.toString()),
      0,
    )
  }, [chats, myJid])

  return (
    <div
      className={classNames(css.timestamp, {
        [css.cardDimText]: unreadCount === 0,
        [css.cardHighlightText]: unreadCount !== 0,
      })}
    >
      <RelativeTimeLabel timestamp={lastChatEvent.timestamp} />
    </div>
  )
}

const ChatCard = ({
  chatJid,
  onClick,
  chatName,
  chatActive,
  chatColor,
  failedSync = false,
  compact,
  className,
}: ChatCardProps) => {
  const { myJid } = useContext(XmppContext)
  const chat = useAppSelector(selectChatCard(chatJid))
  const fullChat = useAppSelector(selectChat(chatJid))

  const driverParicipant = useAppSelector(selectDriverParticipant(chatJid))
  const route = useAppSelector(findRoute(driverParicipant?.jid))
  const activity = useAppSelector(findActivity(driverParicipant?.jid))
  const route2g = useAppSelector(findRoute2g(driverParicipant?.jid))
  const showMultipleDestinations = useFeatureFlagEnabled(
    MULTIPLE_DESTINATIONS_FLAG,
  )

  const lastChatEvent = useMemo(() => {
    return fullChat ? ChatHelpers.getLastChatEvent(fullChat) : undefined
  }, [fullChat])

  const statuses = useAppSelector(
    messageStatuses(fullChat, myJid),
    shallowEqual,
  )
  const lastChatEventStatus =
    !lastChatEvent ||
    lastChatEvent.displayType === MessageDisplayType.Reaction ||
    lastChatEvent?.retracted
      ? undefined
      : statuses[lastChatEvent.id]

  const formatDistance = () => {
    if (!route) return ""

    if (route.distanceState === "ARRIVED") return "Nearby"
    if (route.distanceState === "ARRIVING") return "Arriving"
    if (route.distance >= 1000) {
      return `${Math.floor(route.distance / 1000).toString()} km`
    } else {
      return `${route.distance} m`
    }
  }

  return (
    <div
      onClick={onClick}
      className={classNames(className, css.chatCard, {
        [css.active]: chatActive,
        [css.chatCardCompact]: compact,
        ["opacity-40"]: failedSync,
      })}
    >
      <AvatarImage
        size={compact ? "small" : "large"}
        name={chatName}
        color={chatColor}
        src={participantPhotoToSrc(driverParicipant?.photo)}
        active={activity?.specific === "driving"}
      />
      <div className={css.cardTexts}>
        <div className={css.titleRow}>
          <div className={css.title}>{chatName}</div>
          <div className={css.additionalInfo}>
            {showMultipleDestinations ? (
              <RouteInfo route={route2g} />
            ) : (
              route && <div className={css.distance}>{formatDistance()}</div>
            )}
            <ChatListUnreadCount chatJids={[chatJid]} />
          </div>
        </div>
        {chat && (lastChatEvent || chat.draftMessage) && (
          <div className={css.lastMessageContainer}>
            {lastChatEventStatus && chat.draftMessage?.length === 0 && (
              <ReadStatus
                status={lastChatEventStatus}
                withDriver={!!driverParicipant}
                disabledTooltip={true}
              />
            )}
            {chat && lastChatEvent && (
              <>
                <ChatEventPreview chat={chat} event={lastChatEvent} />
                <LastMessageTimestamp
                  chatJid={chatJid}
                  lastChatEvent={lastChatEvent}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

function ChatEventPreview({
  chat,
  event,
}: {
  chat: CardChat
  event: ChatEvent
}) {
  const { myJid } = useContext(XmppContext)

  const authorName = useAppSelector(messageAuthorName(event, myJid))

  const text = useMemo(() => {
    if (chat.draftMessage && chat.draftMessage.length > 0) {
      return `Draft: ${chat.draftMessage}`
    }

    if (event.displayType === MessageDisplayType.Reaction) {
      if (MessageHelpers.hasComeFromJid(event, myJid.toString())) {
        return `You reacted with ${event.emojis}`
      }

      return `${authorName} reacted with ${event.emojis}`
    }

    if (event.retracted) {
      return <RetractedMessagePlaceholder />
    }

    return event.body || "file attachment"
  }, [myJid, authorName, chat.draftMessage, event])

  return (
    <div className={classNames(css.lastMessage, css.cardDimText)}>{text}</div>
  )
}

export default ChatCard
