import classNames from "classnames"
import React, { memo, useCallback } from "react"
import { isMobile, isMacOs, isWindows } from "react-device-detect"
import css from "./LazyImage.module.css"
import { FileToolbar } from "./FileToolbar"
import { Message } from "../../reducers/chatsSlice"
import { useImageViewContext } from "../photoView/ImageViewContext"
export type LazyImageProps = {
  url: string
  downloadFileName?: string | null
  compact?: boolean
  inGroup?: boolean
  handleDownload?: (url: string, fileName: string) => void
  handleShareLink?: (filename: string) => void
  message?: Message
  nonClickable?: boolean
}

const LazyImage = ({
  url,
  downloadFileName,
  compact,
  handleDownload,
  handleShareLink,
  inGroup,
  message,
  nonClickable,
}: LazyImageProps) => {
  const { onImageClick } = useImageViewContext()
  const handleImageClick = useCallback(
    (event: React.MouseEvent) => {
      const isCtrlOrCmdClick =
        event.button === 0 &&
        ((isWindows && event.ctrlKey) || (isMacOs && event.metaKey))
      const isMiddleClick = event.button === 1
      if (isCtrlOrCmdClick || isMiddleClick) {
        window.open(url)
      } else {
        onImageClick?.(url)
      }
    },
    [url, onImageClick],
  )
  const onMouseDown = !nonClickable ? handleImageClick : undefined
  return (
    <div className="w-full h-full group">
      <div className={classNames(css.imageContainer, "w-full h-full relative")}>
        {handleDownload && handleShareLink && downloadFileName && message && (
          <div>
            <FileToolbar
              url={url}
              downloadFileName={downloadFileName!}
              handleDownload={handleDownload}
              handleShareLink={handleShareLink}
              message={message}
              isMobile={isMobile}
            />
          </div>
        )}
        <img
          src={url}
          onMouseDown={onMouseDown}
          loading="lazy"
          className={classNames(
            css.image,
            "cursor-pointer",
            !inGroup && "object-scale-down",
            inGroup && "w-28 h-28 object-cover rounded-lg",
            compact && "h-20",
          )}
        />
      </div>
    </div>
  )
}

export default memo(LazyImage)
