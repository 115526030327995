import { Element } from "@xmpp/xml"
import parse from "@xmpp/xml/lib/parse"
import type { AppStore } from "./reducers/store"
import { Client } from "@xmpp/client"
import { getItem } from "./reducers/sendoutQueueSelectors"
import { BatchAcker, markFailed } from "./reducers/sendoutQueueSlice"
import { fetchArchivePage } from "./api/xeps/MAM"

type SendoutQueueWorker = {
  send: (messageId: string) => void
}

export const getAckGroupchatId = (stanza: Element): string | undefined => {
  if (!stanza.is("message")) return
  if (stanza.attrs.type === "groupchat") {
    return stanza.attrs.id
  }

  // MAM response
  const mamMessage = stanza
    ?.getChild("result", "urn:xmpp:mam:2")
    ?.getChild("forwarded", "urn:xmpp:forward:0")
    ?.getChild("message")
  if (mamMessage?.attrs?.type === "groupchat") {
    return mamMessage.attrs.id
  }

  return undefined
}

const getLastMessages = async (
  client: Client,
  roomJid: string,
  n: number,
  timeout: number,
): Promise<Element[]> => {
  const result = await fetchArchivePage(
    client,
    {
      toJid: roomJid,
      before: true,
      max: n.toString(),
    },
    timeout,
  )
  return result.accumulator
}

const SendoutQueueWorker = (
  store: AppStore,
  xmpp: Client,
  timeout: number = 3000,
): SendoutQueueWorker => {
  const MAX_ATTEMPTS = 4
  const acker = BatchAcker({ debounce: 200, store })

  xmpp.on("stanza", (stanza: Element) => {
    const id = getAckGroupchatId(stanza)
    if (!id) return

    acker(id)
  })

  const trySendout = async (messageId: string) => {
    const item = getItem(messageId)(store.getState().sendoutQueue)

    if (!item) return
    if (item.messageType !== "groupchat") return

    const xml = parse(item.stanza)
    delete xml.attrs.from

    for (let attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      console.log(
        `[SendoutQueueWorker] Sending ${messageId}, attempt ${attempt}`,
      )
      window.analytics.track("Sendout.Attempt", { messageId, attempt })
      if (attempt === 1) {
        xmpp.send(xml)
        await new Promise((r) => setTimeout(r, timeout))
        const isFound = getItem(messageId)(store.getState().sendoutQueue)
        if (!isFound) {
          console.log(
            `[SendoutQueueWorker] Successfully sent on first try ${messageId}`,
          )
          window.analytics.track("Sendout.SuccessFirstTry", { messageId })
          return
        }
      } else {
        try {
          await getLastMessages(xmpp, item.targetJid, 30, timeout)
          const isFound = getItem(messageId)(store.getState().sendoutQueue)
          if (isFound) {
            console.log(
              `[SendoutQueueWorker] Did not ack ${messageId}, retrying`,
            )
            window.analytics.track("Sendout.NoAck", { messageId, attempt })
            xmpp.send(xml)
          } else {
            console.log(
              `[SendoutQueueWorker] Successfully sent ${messageId}, attempt=${attempt}`,
            )
            window.analytics.track("Sendout.Success", { messageId, attempt })
            return
          }
        } catch (error) {
          console.log(error)
          console.log(
            `[SendoutQueueWorker] Did not fetch archive to find ${messageId}, retrying`,
          )
          window.analytics.track("Sendout.NoFetch", { messageId, attempt })
        } finally {
          await new Promise((r) => setTimeout(r, timeout))
        }
      }
    }

    store.dispatch(markFailed({ messageId: messageId }))
  }

  const send = (messageId: string) => {
    trySendout(messageId)
  }

  return { send }
}

export default SendoutQueueWorker
