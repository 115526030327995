import React, { useState, useEffect } from "react"
import type { OverlayRenderProps } from "react-photo-view/dist/types"
import { isMobile, isIOS } from "react-device-detect"
import { Slider, Tag } from "@blueprintjs/core"
import type { Message } from "../../reducers/chatsSlice"
import useHasFileBeenShared from "../../hooks/useHasFileBeenShared"
import useHandleDownload from "../../hooks/useHandleDownload"
import useHandleShareLink from "../../hooks/useHandleShareLink"
import useDownloadFileName from "../../hooks/useDownloadFileName"
import classNames from "classnames"
import ImageShareButton from "../photoView/ImageShareButton"

export type PDFTopToolbarProps = OverlayRenderProps & {
  totalPages: number
  PDFMessage: Message
  onIndexChange: (index: number) => void
}

export const PDFBottomToolbar = ({
  totalPages,
  index,
  onIndexChange,
  PDFMessage,
}: PDFTopToolbarProps) => {
  const hasFileBeenShared = useHasFileBeenShared(PDFMessage)
  const handleShareLink = useHandleShareLink(PDFMessage)
  const handleDownload = useHandleDownload()
  const downloadFileName = useDownloadFileName(PDFMessage)

  const [sliderIndex, setSliderIndex] = useState(1)

  useEffect(() => {
    setSliderIndex(index)
  }, [index])

  return (
    <div
      className={classNames(
        "absolute justify-center flex flex-col gap-2 right-0 bottom-0 p-2 w-full text-sm text-slate-300 z-50 bg-black/50",
        { "min-h-20": isIOS },
      )}
    >
      {/* Render slider for non-mobile devices with more than 50 pages */}
      {!isMobile && totalPages > 50 && (
        <div className="mx-32">
          <Slider
            className="w-full"
            min={0}
            labelValues={[1, Math.floor(totalPages / 2), totalPages - 1]}
            labelRenderer={(value) => <span>{value + 1}</span>}
            value={sliderIndex}
            initialValue={1}
            onChange={setSliderIndex}
            onRelease={onIndexChange}
            max={totalPages - 1}
          />
        </div>
      )}
      <div className="justify-end flex flex-row gap-2">
        {!isMobile && PDFMessage && handleDownload && downloadFileName && (
          <a
            href={PDFMessage.url}
            download
            onClick={(e) => {
              e.preventDefault()
              handleDownload(PDFMessage.url!, downloadFileName!)
            }}
          >
            <Tag icon="download" round intent="primary" large />
          </a>
        )}
        {PDFMessage && handleShareLink && downloadFileName && (
          <ImageShareButton
            hasFileBeenShared={hasFileBeenShared}
            handleShareLink={handleShareLink}
            downloadFileName={downloadFileName}
          />
        )}
      </div>
    </div>
  )
}
