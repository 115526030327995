import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Button, Tooltip, MenuItem, Popover, Tag } from "@blueprintjs/core"
import { ItemRendererProps, Select } from "@blueprintjs/select"
import { DatePicker3 } from "@blueprintjs/datetime2"

import { Loader } from "@googlemaps/js-api-loader"
import { useDrag, useDrop } from "react-dnd"
import { ChatHistoryContext } from "../contexts/ChatHistoryContext"

import {
  Trash2,
  MessageCircle,
  Grip,
  Info,
  Sparkles,
  MapPin,
  TriangleAlert,
  AlarmClock,
} from "lucide-react"
import { Location } from "../reducers/locationsSlice"
import { captureError } from "../ErrorHandlers"
import {
  Action,
  ACTIONS,
  DRIVER_STATUS_FLAG,
  RouteStop,
} from "../reducers/routes2gSlice"
import { useFeatureFlagEnabled } from "posthog-js/react"

const HOVER_OPEN_DELAY = 500

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOGLE_MAP_KEY || "",
  libraries: ["places"],
})

const DATE_FORMATTER = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "numeric",
})

const formatTimestamp = (date: Date): string => {
  const time = date.toTimeString().slice(0, 5)
  return `${time} ${DATE_FORMATTER.format(date)}`
}

const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.ceil((seconds % 3600) / 60)

  const hoursStr = hours > 0 ? `${hours} h` : ""
  const minutesStr = minutes > 0 ? `${minutes} m` : ""
  const separator = hours > 0 && minutes > 0 ? " " : ""

  return hoursStr + separator + minutesStr || "0 m"
}

type DateDisplayProps = {
  stop: RouteStop
  disabled: boolean
  onDateUpdate: (newDate: Date | null) => void
}
const DateDisplay = ({ stop, disabled, onDateUpdate }: DateDisplayProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleDateChange = (newDate: Date | null): void => {
    setSelectedDate(newDate)
  }

  const togglePopover = (): void => {
    if (!isOpen) {
      setSelectedDate(
        stop.scheduled_arrival_time
          ? new Date(stop.scheduled_arrival_time)
          : null,
      )
    }
    setIsOpen(!isOpen)
  }

  const handleSet = () => {
    setIsOpen(false)
    onDateUpdate(selectedDate)
    window.analytics.track("ScheduleRouteStop", { id: stop.id })
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const Delay = () => {
    if (!stop.scheduled_arrival_time || !stop.estimated_arrival_time) {
      return null
    }

    const differenceInMilliseconds =
      new Date(stop.estimated_arrival_time).getTime() -
      new Date(stop.scheduled_arrival_time).getTime()

    const deltaMinutes = Math.ceil(differenceInMilliseconds / 1000 / 60)

    if (deltaMinutes <= 0) {
      return null
    }

    const delay = Math.floor(Math.abs(differenceInMilliseconds) / 1000)
    const delayDuration = formatDuration(delay)

    return (
      <Tag intent={disabled ? "none" : "warning"} round>
        {delayDuration} late
      </Tag>
    )
  }

  const getIconColor = () => {
    if (disabled) {
      return "gray"
    }

    if (!stop.scheduled_arrival_time) {
      return "black"
    }

    if (stop.timeliness_status === "late") {
      return "red"
    }

    return "green"
  }

  const renderTooltipContent = () => {
    return (
      <div>
        {stop.scheduled_arrival_time ? (
          <>
            <div>
              {stop.timeliness_status === "late"
                ? "Driver late"
                : "Driver on time"}
            </div>
            <div>
              Scheduled arrival time:{" "}
              {formatTimestamp(new Date(stop.scheduled_arrival_time))}
            </div>
          </>
        ) : (
          <div className="pb-2">Set scheduled arrival time</div>
        )}
        {stop.estimated_arrival_time && (
          <div>
            Estimated arrival time:{" "}
            {formatTimestamp(new Date(stop.estimated_arrival_time))}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="flex gap-[2px] items-center">
      <Popover
        isOpen={isOpen}
        onClose={handleClose}
        interactionKind="click"
        placement="auto"
        content={
          <DatePicker3
            defaultValue={selectedDate ? selectedDate : undefined}
            onChange={handleDateChange}
            timePrecision="minute"
            timePickerProps={{ showArrowButtons: true }}
            showActionsBar={true}
            highlightCurrentDay={true}
            footerElement=<Button
              minimal
              className="font-bold"
              onClick={handleSet}
            >
              Set scheduled arrival time
            </Button>
          />
        }
        renderTarget={({ isOpen, ...targetProps }) => (
          <div
            className={
              !stop.scheduled_arrival_time
                ? "invisible group-hover:visible"
                : ""
            }
          >
            <Tooltip
              content={renderTooltipContent()}
              hoverOpenDelay={HOVER_OPEN_DELAY}
            >
              <Button
                {...targetProps}
                onClick={togglePopover}
                minimal={true}
                disabled={!stop.is_active}
              >
                <AlarmClock size={16} color={getIconColor()} />
              </Button>
            </Tooltip>
          </div>
        )}
      />
      <Delay />
    </div>
  )
}

type ActionDisplayProps = {
  stop: RouteStop
  disabled: boolean
  onActionUpdate: (action: string) => void
}

const ActionDisplay = ({
  stop,
  disabled,
  onActionUpdate,
}: ActionDisplayProps) => {
  const getActionByValue = (value: string | undefined): Action => {
    const action = ACTIONS.find(
      (action) => action.value.toLowerCase() === value,
    )
    return action ?? ACTIONS.find((action) => action.value === "other")!
  }

  const [selectedAction, setSelectedAction] = useState<Action>(
    getActionByValue(stop.action),
  )

  useEffect(() => {
    setSelectedAction(getActionByValue(stop.action))
  }, [stop])

  const handleItemSelect = (action: Action) => {
    setSelectedAction(action)
    onActionUpdate(action.value)
  }

  const renderAction = (
    option: Action,
    { handleClick, handleFocus, modifiers }: ItemRendererProps,
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={option.value}
        icon={
          <option.icon className="mr-2 text-gray-600" color="gray" size={16} />
        }
        text={option.label}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        selected={selectedAction?.value === option.value}
      />
    )
  }

  return (
    <Select<Action>
      items={ACTIONS}
      activeItem={selectedAction}
      itemRenderer={renderAction}
      onItemSelect={handleItemSelect}
      filterable={false}
    >
      <Tooltip
        content={
          !disabled && selectedAction.value === "other"
            ? "Set stop type"
            : selectedAction.label
        }
        hoverOpenDelay={HOVER_OPEN_DELAY}
      >
        <Button minimal={true} disabled={!stop.is_active}>
          <div className="flex gap-0 w-[32px] pl-[8px]">
            <selectedAction.icon
              size={16}
              color={disabled ? "gray" : "black"}
            />
            {stop.by?.startsWith("assistant") && <Sparkles size={10} />}
          </div>
        </Button>
      </Tooltip>
    </Select>
  )
}

type LocationDisplayProps = {
  stop: RouteStop
  disabled: boolean
  onLocationUpdate: (location: Location) => void
}
const LocationDisplay = ({
  stop,
  disabled,
  onLocationUpdate,
}: LocationDisplayProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [address, setAddress] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [places, setPlaces] = useState<google.maps.PlacesLibrary>()

  const getCoordinates = (location: Location) => {
    if (location.lat && location.lon) {
      const lat = parseFloat(location.lat).toFixed(4)
      const lon = parseFloat(location.lon).toFixed(4)
      return `${lat}, ${lon}`
    }
    return undefined
  }

  const formatAddress = useCallback(
    (location: Location) => {
      const coordinates = getCoordinates(location)
      if (location.countryCode && location.locality) {
        setAddress(
          `${location.countryCode}, ${location.postalCode ? location.postalCode : ""} ${location.locality}`,
        )
        setIsEditing(false)
      } else if (coordinates) {
        setAddress(coordinates)
        setIsEditing(false)
      } else if (location.formattedAddress) {
        setAddress(location.formattedAddress)
        setIsEditing(false)
      } else {
        setAddress("")
        setIsEditing(true)
      }
    },
    [setAddress, setIsEditing],
  )

  useEffect(() => {
    formatAddress(stop.location)
  }, [stop.location, formatAddress])

  useEffect(() => {
    loader
      .importLibrary("places")
      .then((places) => {
        setPlaces(places)
      })
      .catch((error) => {
        captureError(error, {
          origin: "InitialLoad",
          extra: { message: "Loading google places library" },
        })
      })
  }, [])

  const parseCoordinates = useCallback((input: string): string | null => {
    const regex = /(-?\d+[.,]?\d*)\s*[,\s]+\s*(-?\d+[.,]?\d*)/
    const match = input.match(regex)

    if (match) {
      const latStr = match[1].replace(",", ".")
      const lonStr = match[2].replace(",", ".")

      const lat = parseFloat(latStr)
      const lon = parseFloat(lonStr)

      if (!isNaN(lat) && !isNaN(lon)) {
        return `${latStr}, ${lonStr}`
      }
    }

    return null
  }, [])

  useEffect(() => {
    if (!places) return

    if (isEditing && inputRef.current) {
      const autocomplete = new places.Autocomplete(inputRef.current)

      const listener = autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace()

        if (place.formatted_address) {
          const location: Location = {
            lat: place.geometry?.location?.lat()?.toString(),
            lon: place.geometry?.location?.lng()?.toString(),
            formattedAddress: place.formatted_address,
          }
          place.address_components?.forEach((component: any) => {
            if (component.types.includes("country")) {
              location.countryCode = component.short_name
            }
            if (component.types.includes("locality")) {
              location.locality = component.long_name
            }
            if (component.types.includes("postal_code")) {
              location.postalCode = component.long_name
            }
          })
          onLocationUpdate(location)
        } else if (place.name && parseCoordinates(place.name)) {
          const coordinates = parseCoordinates(place.name)
          if (coordinates) {
            onLocationUpdate({
              formattedAddress: coordinates,
            })
          }
        } else {
          formatAddress(stop.location)
        }
      })

      return () => listener.remove()
    }
  }, [
    places,
    isEditing,
    stop.location,
    onLocationUpdate,
    formatAddress,
    parseCoordinates,
  ])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const autocompleteEl = document.querySelector(".pac-container")
      const target = event.target as HTMLElement
      const isAutocompleteClick =
        target.classList.contains("pac-item") ||
        target.classList.contains("pac-item-query") ||
        target.closest(".pac-item") !== null

      if (
        inputRef.current &&
        !inputRef.current.contains(target) &&
        (!autocompleteEl ||
          (!autocompleteEl.contains(target) && !isAutocompleteClick))
      ) {
        setIsEditing(false)
        formatAddress(stop.location)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [setIsEditing, formatAddress, stop.location])

  const handleClick = () => {
    if (disabled) return

    setAddress(stop.location.formattedAddress || "")
    setIsEditing(true)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      formatAddress(stop.location)
    }
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value)
  }

  const formatFullLocationInfo = () => {
    const coordinates = getCoordinates(stop.location)
    if (coordinates || stop.location.formattedAddress) {
      return (
        <div>
          {stop.location.formattedAddress && (
            <div>{stop.location.formattedAddress}</div>
          )}
          {coordinates && <div>{coordinates}</div>}
        </div>
      )
    }
    return "Destination Address"
  }

  return (
    <div
      className={`w-60 flex items-center rounded-sm ${!disabled && "transition-colors duration-300 ease-in-out hover:bg-gray-200"}`}
      onClick={handleClick}
    >
      {isEditing ? (
        <input
          value={address}
          onChange={handleAddressChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter address"
          ref={inputRef}
          className="w-full border border-gray-300 rounded px-2 py-1 "
          autoFocus
        />
      ) : (
        <Tooltip
          content={formatFullLocationInfo()}
          hoverOpenDelay={HOVER_OPEN_DELAY}
          className="w-full"
        >
          <div
            className={`cursor-default ${stop.is_active && "hover:cursor-pointer"} w-full px-2 py-1 truncate`}
          >
            <span className="">{address}</span>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

type RouteStopViewProps = {
  stop: RouteStop
  index: number
  onUpdate: (stop: RouteStop, index: number) => void
  onRemove: (index: number) => void
  onMove: (from: number, to: number) => void
  onMoveCompleted: () => void
}

const RouteStopView = ({
  stop,
  index,
  onUpdate,
  onRemove,
  onMove,
  onMoveCompleted,
}: RouteStopViewProps) => {
  const [partialStop, setPartialStop] = useState<RouteStop>(stop)
  const { jumpAndHighlightMessage } = useContext(ChatHistoryContext)
  const ref = React.useRef<HTMLDivElement>(null)
  const ItemType = "ROUTE_STOP"
  const showDriverStatus = useFeatureFlagEnabled(DRIVER_STATUS_FLAG)

  useEffect(() => {
    setPartialStop(stop)
  }, [stop])

  const isNewStop = (stop: RouteStop): boolean => {
    return !stop.id
  }

  const disabled = useMemo(() => {
    // enabled stops are stops that are being added or are active
    return !(isNewStop(stop) || stop.is_active)
  }, [stop])

  const updateLocation = useCallback(
    (location: Location) => {
      const updated = {
        ...partialStop,
        location,
        msgId: undefined,
        addressUpdated: true,
      }
      onUpdate(updated, index)
    },
    [partialStop, index, onUpdate],
  )

  const getLocalISOString = (date: Date): string => {
    const offset = date.getTimezoneOffset() * 60000
    return new Date(date.getTime() - offset).toISOString().slice(0, -1)
  }

  const updateDate = useCallback(
    (date: Date | null) => {
      const updated = {
        ...partialStop,
        scheduled_arrival_time: date ? getLocalISOString(date) : undefined,
      }

      if (isNewStop(partialStop)) {
        setPartialStop(updated)
      } else {
        onUpdate(updated, index)
      }
    },
    [partialStop, setPartialStop, index, onUpdate],
  )

  const updateAction = useCallback(
    (action: string) => {
      const updated = {
        ...partialStop,
        action: action,
      }
      if (isNewStop(partialStop)) {
        setPartialStop(updated)
      } else {
        onUpdate(updated, index)
      }
    },
    [partialStop, setPartialStop, index, onUpdate],
  )

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: { index: number }) {
      if (!ref.current || disabled) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      onMove(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: () => !disabled,
    end(_, monitor) {
      if (monitor.didDrop()) {
        onMoveCompleted()
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const JumpToMessageButton = () => {
    if (stop.msgId) {
      return (
        <Tooltip content="Show message" hoverOpenDelay={HOVER_OPEN_DELAY}>
          <Button
            minimal={true}
            onClick={() => {
              if (stop.msgId) jumpAndHighlightMessage(stop.msgId)
            }}
            className="invisible group-hover:visible"
          >
            <MessageCircle size={16} />
          </Button>
        </Tooltip>
      )
    }
    return <></>
  }

  const FullInfoButton = () => {
    return (
      <Tooltip
        content={
          <pre
            style={{
              margin: 0,
              whiteSpace: "pre-wrap",
              wordBreak: "break-all",
            }}
          >
            {JSON.stringify(partialStop, null, 4)}
          </pre>
        }
        hoverOpenDelay={HOVER_OPEN_DELAY}
      >
        <Info
          size={16}
          className="invisible group-hover:visible mr-3 cursor-pointer"
        />
      </Tooltip>
    )
  }

  const Distance = () => {
    if (!stop.proximity_status) return null

    const capitalize = (input: string | undefined): string => {
      if (!input) return ""
      return input.charAt(0).toUpperCase() + input.slice(1)
    }

    const formatDistance = (distance: number) => {
      if (distance >= 1000) {
        return `${Math.floor(distance / 1000).toString()} km`
      }

      return `${distance} m`
    }

    const renderDistanceValue = () => {
      if (stop.proximity_status === "arrived") return "Nearby"

      if (stop.proximity_status === "missed") return "Skipped"

      if (!stop.is_active || stop.proximity_status === "arriving") {
        return capitalize(stop.proximity_status)
      }

      if (!stop.remaining_distance) return "?"

      return formatDistance(stop.remaining_distance)
    }

    const getDistanceIntent = () => {
      switch (stop.proximity_status) {
        case "left":
          return "none"
        case "missed":
          return "none"
        default:
          return stop.is_current_target === true ? "success" : "none"
      }
    }

    return (
      <Tooltip
        content={
          stop.remaining_time &&
          stop.remaining_distance &&
          !["missed", "left", "arrived"].includes(stop.proximity_status) ? (
            <div>
              <div>
                Remaining distance: {formatDistance(stop.remaining_distance)}
              </div>
              <div>Remaining time: {formatDuration(stop.remaining_time)}</div>
            </div>
          ) : (
            "Status"
          )
        }
        hoverOpenDelay={HOVER_OPEN_DELAY}
      >
        <div className="flex justify-end items-center w-20 mx-2 cursor-default">
          <Tag
            intent={getDistanceIntent()}
            icon={
              stop.proximity_status === "missed" ? (
                <TriangleAlert size={12} />
              ) : null
            }
            round
          >
            {renderDistanceValue()}
          </Tag>
        </div>
      </Tooltip>
    )
  }

  const DeleteButton = () => {
    return (
      <Tooltip content="Remove destination" hoverOpenDelay={0}>
        <Button
          minimal={true}
          onClick={() => onRemove(index)}
          className="invisible group-hover:visible"
        >
          <Trash2 className="" size={16} color="red" />
        </Button>
      </Tooltip>
    )
  }

  const renderLocationIcon = () => {
    return (
      <div className="flex gap-0 w-[46px] pl-[18px]">
        <MapPin size={16} />
        {stop.by?.startsWith("assistant") && <Sparkles size={10} />}
      </div>
    )
  }

  return (
    <div
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className={`${disabled ? "cursor-default" : "cursor-moved"} p-1 m-1 flex gap-0 ${disabled && "text-gray-500"}`}
    >
      <div className="flex items-center group">
        <Grip
          color="gray"
          size={18}
          className={`invisible ${!disabled && "group-hover:visible"}`}
        />
      </div>
      <div className="group flex gap-2 items-center">
        {showDriverStatus ? (
          <ActionDisplay
            stop={partialStop}
            disabled={disabled}
            onActionUpdate={updateAction}
          />
        ) : (
          renderLocationIcon()
        )}
        <LocationDisplay
          stop={partialStop}
          disabled={disabled}
          onLocationUpdate={updateLocation}
        />
        <Distance />
        {showDriverStatus && (
          <DateDisplay
            stop={partialStop}
            disabled={disabled}
            onDateUpdate={updateDate}
          />
        )}
        <div className="group">
          <div className="flex items-center gap-0 cursor-pointer">
            {false && <FullInfoButton />}
            <JumpToMessageButton />
            <DeleteButton />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RouteStopView
