import { Client } from "@xmpp/client"
import { performInitialLoad } from "../routes/archiveLoader"
import { pingServer } from "../api/xmppPing"
import XmppApi from "../api/xmppApi"
import XmppPubSubApi from "../api/xmppPubSubApi"
import { retry } from "../utils"
import { captureError } from "../ErrorHandlers"

let pingEvery: number = 1
let consecutiveFailedPings: number = 0
let scheduleSync: boolean = true

const MAX_FAILED_PINGS_TO_CONSIDER_FAULTY = 3

const setPingEvery = (value: number) => {
  pingEvery = value
}

const getPingInterval = (delay: number | null) => {
  if (delay) {
    if (pingEvery <= 32) setPingEvery(pingEvery * 2)
  } else {
    setPingEvery(1)
  }

  return pingEvery
}

const trackEvent = (message: string) => {
  window.analytics.track("KeepConnected", {
    pingEvery,
    consecutiveFailedPings,
    scheduleSync,
    message,
  })
}

const getIsHealthyConnection = (): boolean => {
  return consecutiveFailedPings < MAX_FAILED_PINGS_TO_CONSIDER_FAULTY
}

const subscribeToBookmarks = async (client: Client) => {
  try {
    await XmppPubSubApi.subscribe(
      client,
      client.jid?.bare().toString() as string,
      "urn:xmpp:bookmarks:1",
    )
  } catch (error: any) {
    if (error.condition !== "item-not-found") {
      captureError(error, {
        origin: "XMPPOnOnline",
        extra: { message: "subscribeToBookmarks" },
      })
    }
  }
}

const runFullSync = async (client: Client) => {
  trackEvent("Full sync")
  try {
    await pingServer(client, 1000)
  } catch {
    await client.stop()
    await client.start()
  }
  await subscribeToBookmarks(client)
  await XmppApi.sendPresence(client)
  await performInitialLoad(client, window.store)
  console.warn("KeepConnected connection restarted")
}

const keepConnectedIteration = async (client: Client): Promise<number> => {
  if (document.visibilityState === "hidden") {
    return 2000
  }

  const delay = await pingServer(client, 5000)
  if (delay) {
    consecutiveFailedPings = 0
  } else {
    trackEvent("Ping Failed")
    consecutiveFailedPings += 1
  }
  const isHealthy = getIsHealthyConnection()
  if (!delay) {
    console.warn("KeepConnected ping failed", {
      pingEvery,
      isHealthy,
      consecutiveFailedPings,
      scheduleSync,
    })
  }

  if (!isHealthy) {
    scheduleSync = true
  }

  if (scheduleSync) {
    try {
      console.warn("KeepConnected sync", { scheduleSync, isHealthy })
      await retry(runFullSync, [client], {
        maxAttempts: 1000,
        backoff: true,
      })
      scheduleSync = false
      setPingEvery(1)
    } catch {
      console.warn("KeepConnected could not do full sync")
    }
  }

  const nextInterval = getPingInterval(delay)
  return 1000 * nextInterval
}

const keepConnectedLoop = async (client: Client) => {
  let timeout = 2000
  try {
    timeout = await keepConnectedIteration(client)
  } catch (error: any) {
    console.error("Error during keepConnected")
  } finally {
    console.log("KeepConnected loop", timeout)
    setTimeout(() => {
      void keepConnectedLoop(client)
    }, timeout)
  }
}

let currentVisibilityState: null | "visible" | "hidden" = null

window.addEventListener("visibilitychange", () => {
  if (currentVisibilityState !== document.visibilityState) {
    console.log(
      `KeepConnected page visibility change ${currentVisibilityState} -> ${document.visibilityState}`,
    )
    setPingEvery(1)
    currentVisibilityState = document.visibilityState
  }
})

const disconnectOnLeavingPage = async (xmpp: Client) => {
  window.analytics.track("PageUnload")
  console.info("Disconnecting XMPP before quitting")
  await xmpp.stop()
}

export const keepConnected = (xmpp: Client) => {
  console.log("KeepConnected start")
  xmpp.on("online", () => {
    scheduleSync = true
  })
  xmpp.on("reconnected", () => {
    scheduleSync = true
  })

  window.addEventListener("beforeunload", async () => {
    await disconnectOnLeavingPage(xmpp)
  })
  void keepConnectedLoop(xmpp)
}
