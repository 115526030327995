import classNames from "classnames"
import React, { memo, useCallback } from "react"
import { isMobile, isWindows, isMacOs } from "react-device-detect"
import { FileToolbar } from "./FileToolbar"
import { Message } from "../../reducers/chatsSlice"
import { usePDFViewerContext } from "../pdfView/PDFViewerContext"

export type FileStubProp = {
  url: string
  name?: string
  downloadFileName?: string | null
  handleDownload?: (url: string, name: string) => void
  handleShareLink?: (filename: string) => void
  inGroup?: boolean
  message?: Message
  nonClickable?: boolean
}

const getFileName = (url: string, name?: string) => {
  if (name) return name

  const nameStart = url.lastIndexOf("/") + 1
  return url.slice(nameStart, url.length)
}

const FileStub = ({
  url,
  name,
  downloadFileName,
  handleDownload,
  handleShareLink,
  inGroup,
  message,
  nonClickable,
}: FileStubProp) => {
  const isPDF = downloadFileName?.endsWith("pdf")
  const { onPDFClick } = usePDFViewerContext()
  const handlePDFClick = useCallback(
    (event: React.MouseEvent) => {
      const isCtrlOrCmdClick =
        event.button === 0 &&
        ((isWindows && event.ctrlKey) || (isMacOs && event.metaKey))
      const isMiddleClick = event.button === 1
      if (isCtrlOrCmdClick || isMiddleClick) {
        window.open(url)
      } else {
        onPDFClick?.(message!)
      }
    },
    [message, url, onPDFClick],
  )
  const FileComponent = () => (
    <>
      {isPDF && (
        <img
          src="pdf.svg"
          className="w-10 h-10 cursor-pointer"
          onMouseDown={handlePDFClick}
        />
      )}
      <span
        className={classNames(
          "break-words text-clip line-clamp-2",
          inGroup && "max-w-20",
        )}
      >
        {getFileName(url, name)}
      </span>
    </>
  )

  return (
    <div className="group min-h-40 min-w-40 flex items-center relative">
      <div className="flex flex-col justify-center items-center gap-y-2 text-center text-xs h-full w-full">
        {handleDownload && handleShareLink && downloadFileName && message && (
          <div>
            <FileToolbar
              url={url}
              downloadFileName={downloadFileName!}
              handleDownload={handleDownload}
              handleShareLink={handleShareLink}
              message={message}
              isMobile={isMobile}
            />
          </div>
        )}
        <div className="flex flex-col justify-center items-center gap-y-2 text-center text-xs h-full w-full">
          {nonClickable || isPDF ? (
            <FileComponent />
          ) : (
            <a href={url} target="_blank" rel="noreferrer">
              <FileComponent />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(FileStub)
