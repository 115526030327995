import React, { useRef, useState, useMemo, useEffect } from "react"
import { PhotoSlider } from "react-photo-view"
import "react-photo-view/dist/react-photo-view.css"
import { ImageTopToolbar } from "./ImageTopToolbar"
import { ImageBottomToolbar } from "./ImageBottomToolbar"
import type { Message } from "../../reducers/chatsSlice"
import { mapFileUrlToSameOrigin } from "../message/utils/mapFileToSameOrigin"
import Loader from "../Loader"

export type ImageGalleryProps = {
  messages: Message[]
  visible: boolean
  onClose: () => void
  initialImage?: string
}

const isMessageWithImage = (message: Message) => {
  const m = message.forwarded || message
  return !m.retracted && m.file && m.url && /\.png|\.jpg|\.jpeg/i.test(m.url)
}

export default function ImageGallery({
  messages,
  onClose,
  visible,
  initialImage,
}: ImageGalleryProps) {
  const imgRef = useRef<HTMLElement | null>(null)
  const images = useMemo(
    () =>
      messages
        .filter(isMessageWithImage)
        .map((messageOrForwardedMessage: Message) => {
          const message =
            messageOrForwardedMessage.forwarded || messageOrForwardedMessage
          const isInitialImage = message.url === initialImage
          if (isInitialImage) {
            imgRef.current = window.document.querySelector(
              `img[src="${message.url}"]`,
            )
          }
          return {
            src: mapFileUrlToSameOrigin(message.url!),
            key: message.id,
            originRef: isInitialImage ? imgRef : undefined,
            message,
          }
        }),
    [messages, initialImage],
  )

  const initialIndex = useMemo(() => {
    return initialImage ? images.findIndex((i) => i.src === initialImage) : 0
  }, [initialImage, images])

  const [index, setIndex] = useState(initialIndex)

  // If there is no image, then close the gallery. This could happen if
  // someone has deleted a message when someone else has the gallery open.

  if (images.length === 0) {
    onClose()
    return null
  }
  // If the message with the last image has been deleted,
  // we need to adjust the index to the latest available image.
  const adjustedIndex = index >= images.length ? images.length - 1 : index
  return (
    <PhotoSlider
      /*
       * Overwrite z-index of PhotoView-Portal
       * The default z-index of 2000 causes issues
       * with toast visibility, as toast overlays have a lower z-index.
       * Reducing this z-index ensures toasts appear on top of the open gallery,
       * maintaining proper visibility of important notifications.
       */
      className="!z-30"
      images={images}
      visible={visible}
      onClose={onClose}
      index={adjustedIndex}
      onIndexChange={setIndex}
      loadingElement={<Loader />}
      toolbarRender={(props) => <ImageTopToolbar {...props} />}
      overlayRender={(props) => <ImageBottomToolbar {...props} />}
    />
  )
}
