import { Element } from "@xmpp/xml"
import { Route2g, RouteStop } from "../reducers/routes2gSlice"
import LocationParser from "./locationParser"

class Route2gParser {
  static readonly NAMESPACE = "urn:slickshift:route:0"

  static parse(stanza: Element): Route2g | undefined {
    const route = this.getCurrent(stanza)?.getChild("route", this.NAMESPACE)

    if (!route) return

    const stops = route
      .getChildren("stop")
      .map((item) => this.parseRouteStop(item))
      .filter((stop) => stop !== undefined) as RouteStop[]

    return {
      jid: stanza.attrs.from,
      stops: stops,
    }
  }

  static parseRouteStop(item: Element): RouteStop | undefined {
    const geoloc = item.getChild("geoloc")
    if (!geoloc) {
      return undefined
    }
    const location = LocationParser.parseGeoloc(geoloc)
    if (!location) {
      return undefined
    }
    return {
      id: item.getAttr("id"),
      by: item.getAttr("by"),
      location: location,
      msgId: item.getAttr("msg-id"),
      action: item.getAttr("action"),
      ref: item.getAttr("ref"),
      remaining_distance: this.parseIntIfNotNull(
        item.getAttr("remaining_distance"),
      ),
      remaining_time: this.parseIntIfNotNull(item.getAttr("remaining_time")),
      scheduled_arrival_time: item.getAttr("scheduled_arrival_time"),
      estimated_arrival_time: item.getAttr("estimated_arrival_time"),
      proximity_status: item.getAttr("proximity_status"),
      timeliness_status: item.getAttr("timeliness_status"),
    }
  }

  static parseIntIfNotNull(value: string | null): number | undefined {
    return value !== null ? parseInt(value, 10) : undefined
  }

  static getCurrent(stanza: Element) {
    const pubSubEventNode =
      stanza.getChild("event", "http://jabber.org/protocol/pubsub#event") ||
      stanza.getChild("pubsub", "http://jabber.org/protocol/pubsub")

    return pubSubEventNode
      ?.getChildByAttr("node", this.NAMESPACE)
      ?.getChildByAttr("id", "current")
  }
}

export default Route2gParser
