import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import ChatHelpers from "../lib/chatHelpers"
import { parse as jidParse } from "../lib/cachedJid"
import { PURGE } from "redux-persist"
import MarkerHelpers from "../lib/markerHelpers"
import MessageHelpers from "../lib/messageHelpers"
import * as Sentry from "@sentry/react"
import { jid } from "@xmpp/client"
import groupBy from "../lib/groupBy"

export type MarkerName = "received" | "displayed"
export type MessageType = "chat" | "groupchat"
export type SendStatus = "sending" | "sent" | "error"

export type MessageRetraction = {
  id: string
  stanzaId?: string
  from: string
  to: string
  messageId: string
  timestamp: string
  type: string
  displayType: MessageDisplayType.Retraction
}

export type Marker = {
  id: string
  to: string
  from: string
  name: MarkerName
  markedMessageId: string
  stanzaId?: string
  timestamp: string
  type: MessageType
  sendStatus?: SendStatus
  displayType: MessageDisplayType.Marker
}

export type Reference = {
  type: "address"
  begin: number
  end: number
}

export type Reaction = {
  id: string
  to: string
  from: string
  messageId: string
  emojis: string[]
  type: MessageType
  timestamp: string
  displayType: MessageDisplayType.Reaction
}

export type MessageFileShared = {
  id: string
  to: string
  from: string
  messageId: string
  fileUrl: string
  userJid: string
  type: MessageType
  timestamp: string
  displayType: MessageDisplayType.MessageFileShared
}

export enum MessageDisplayType {
  Message,
  MessageGroup,
  Marker,
  Reaction,
  MessageFileShared,
  Retraction,
}

export type Message = {
  from: string
  to: string
  type: MessageType
  id: string
  originId?: string
  body: string
  translation?: string
  markers: Marker[]
  reactions: Reaction[]
  timestamp: string
  forwarded?: Message
  file?: boolean
  url?: string
  fileName?: string
  references?: Reference[]
  replaceId?: string
  missedCallNumber?: string
  replyMessage?: MessageFallback
  displayType: MessageDisplayType.Message
  retracted?: boolean
}

export type MessageGroup = {
  messages: Message[]
  displayType: MessageDisplayType.MessageGroup
  groupId: string
}

export const hasTheId = (m: Message | MessageGroup, id: string): boolean => {
  if (m.displayType === MessageDisplayType.MessageGroup) {
    return m.messages?.some((msg) => msg.id === id)
  }

  return m.id === id
}

export type MessageFallback = {
  id: string
  author: string
  body: string
}

export type GenericMessage =
  | Message
  | Marker
  | Reaction
  | MessageFileShared
  | MessageRetraction

export type Participant = {
  name: string
  jid: string
  phone?: string
  plates?: string
  role: string
  photo?: {
    data: string
    type: string
  }
}

export type ChatConnectionStatus = "connected" | "disconnected" | "failed"

type MessageId = string
type UserJid = string
type UnassignedReactionsMap = Record<MessageId, Record<UserJid, Reaction>>

export type ChatEvent = Message | Reaction

type MessageFileSharesMap = Record<MessageId, MessageFileShared>
export type MessageRetractionsMap = Record<MessageId, MessageRetraction>

export type Chat = {
  active: boolean
  jid: string
  name: string
  messages: Message[]
  messageCorrections: Message[]
  messageRetractions: MessageRetractionsMap
  reactions: UnassignedReactionsMap
  lastNonMessageEvent?: ChatEvent
  connectionStatus?: ChatConnectionStatus
  room?: boolean
  participants: Participant[]
  draftMessage: string
  messageIdsWithFileShared: MessageFileSharesMap
  groups: string[]
}

type ChangeActiveChatAction = {
  activeChatJid: string
}

type UpsertChatAction = {
  chat: Pick<Chat, "jid" | "name" | "room">
}

type RemoveChatAction = {
  jid: string
}

type RefreshChatsAction = {
  chats: Chat[]
}

type LimitChatSizeAction = {
  jid: string
  limit: number
}

export type AddMessageAction = {
  message: Message
  myJid: string
  mode?: "append" | "prepend"
}

type AddBatchMessagesToChatAction = {
  messages: Message[]
  myJid: string
  mode: "append" | "prepend"
}

type MarkMessageAction = {
  marker: Marker
  myJid: string
}

type BatchMarkMessageAction = {
  markers: Marker[]
  myJid: string
}

export type AddParticipantAction = {
  chatJid: string
  participant: Participant
}

type UpdateDraftMessage = {
  chatJid: string
  draftMessage: string
}

type AddMessageCorrectionAction = Omit<AddMessageAction, "mode">
type BatchAddMessageCorrectionAction = {
  corrections: Message[]
  myJid: string
}

type AddMessageRetractionAction = {
  retraction: MessageRetraction
  myJid: string
}

type UpdateChatNameAction = {
  chatJid: string
  name: string
}

export type ChatsState = Chat[]

const initialState: ChatsState = []

export const buildChat = (chat: Partial<Chat>): Chat => {
  return {
    name: "",
    messages: [],
    jid: "",
    active: false,
    connectionStatus: undefined,
    participants: [],
    reactions: {},
    draftMessage: "",
    messageCorrections: [],
    messageRetractions: {},
    messageIdsWithFileShared: {},
    groups: [],
    ...chat,
  }
}

// We must change referece here so the redux understand what has been changed on state
const moveChatToTheTop = (chatIndex: number, chats: Chat[]) => {
  if (chatIndex === 0) return
  chats.unshift(chats.splice(chatIndex, 1)[0])
}

const isSameMessage = (a: Message, b: Message): boolean => {
  if (a.originId && b.originId && a.originId === b.originId) return true

  return a.id === b.id
}

const updateStateUsingMarker = (
  state: Chat[],
  marker: Marker,
  myJid: string,
) => {
  const chatIndex = ChatHelpers.findMessageChatIndex(marker, state, myJid)

  if (chatIndex === -1) {
    console.error("Can not find a chat marker corresponds to: ", marker)
    return
  }

  // There may be a situation when message is duplicated in the state
  // (server sent it twice with the same ID or some internal bug in the client)
  // In such case add ChatMarker to all of the found messages.
  const messageIndexes = state[chatIndex].messages.reduce(
    (indexes: number[], message: Message, i: number) => {
      if (message.id === marker.markedMessageId) {
        indexes.push(i)
      }
      return indexes
    },
    [],
  )

  if (messageIndexes.length === 0) {
    console.error(
      "Can not find corresponding message for chat marker: ",
      marker,
    )
    return
  }

  messageIndexes.forEach((index: number) => {
    const message = state[chatIndex].messages[index]
    const markerIndex = message.markers.findIndex(
      (m: any) =>
        MarkerHelpers.isDuplicate(m, marker) ||
        MarkerHelpers.isEqual(m, marker),
    )
    if (markerIndex !== -1) {
      message.markers[markerIndex] = marker

      if (
        !MessageHelpers.isIncoming(marker, jidParse(myJid)) &&
        !marker.sendStatus
      ) {
        message.markers[markerIndex].sendStatus = "sent"
      }
    } else {
      message.markers.push(marker)
    }
  })

  compactMarkers(state[chatIndex], marker.from)
}

const updateStateUsingCorrection = (
  state: Chat[],
  correction: Message,
  myJid: string,
) => {
  const chatIndex = ChatHelpers.findMessageChatIndex(correction, state, myJid)

  if (chatIndex === -1) {
    console.error("Can not find a chat message corresponds to: ", correction)
    return
  }

  state[chatIndex].messageCorrections.push(correction)
}

const shouldCollapseMessages = (
  message1: Message,
  message2: Message,
): boolean => {
  if (message1.retracted || message2.retracted) return false

  const isFileGroupable = (message1: Message, message2: Message): boolean => {
    const groupableRegex = /\.png|\.jpg|\.jpeg|\.webp|\.pdf/i

    return (
      !!message1.url &&
      groupableRegex.test(message1.url) &&
      !!message2.url &&
      groupableRegex.test(message2.url)
    )
  }
  if (message1.replyMessage || message2.replyMessage) {
    return false
  }

  if (
    (message1.forwarded && !message2.forwarded) ||
    (!message1.forwarded && message2.forwarded)
  ) {
    return false
  }

  if (message1.forwarded && message2.forwarded) {
    message1 = message1.forwarded
    message2 = message2.forwarded
  }

  return (
    isFileGroupable(message1, message2) &&
    message1.from === message2.from &&
    (Date.parse(message1.timestamp) - Date.parse(message2.timestamp)) / 1000 <=
      300
  )
}

export const collapseImageGroups = (
  messages: Message[],
  expandedGroups: Set<string>,
): Array<Message | MessageGroup> => {
  const grouped = messages.reduce((acc: Message[][], message: Message) => {
    if (acc.length === 0) {
      acc.push([message])
    } else {
      const lastGroup = acc[acc.length - 1]
      if (shouldCollapseMessages(message, lastGroup[lastGroup.length - 1])) {
        lastGroup.push(message)
      } else {
        acc.push([message])
      }
    }

    return acc
  }, [])

  return grouped.flatMap((group) => {
    if (group.length === 1) {
      return group[0]
    }

    const currentGroupId = group[group.length - 1].id

    if (expandedGroups.has(currentGroupId)) {
      return group
    }

    return {
      messages: group,
      displayType: MessageDisplayType.MessageGroup,
      groupId: currentGroupId,
    } as MessageGroup
  })
}

const handleChatEvent = (
  state: Chat[],
  chat: Chat,
  event: ChatEvent,
  myJid: string,
) => {
  if (
    !chat.lastNonMessageEvent ||
    chat.lastNonMessageEvent.timestamp < event.timestamp
  ) {
    if (
      event.displayType === MessageDisplayType.Reaction &&
      event.emojis.length === 0
    ) {
      if (
        chat.lastNonMessageEvent?.displayType === MessageDisplayType.Reaction &&
        chat.lastNonMessageEvent.messageId === event.messageId
      ) {
        chat.lastNonMessageEvent = undefined
      }

      return
    }

    chat.lastNonMessageEvent = event

    const chatIndex = ChatHelpers.findMessageChatIndex(event, state, myJid)
    moveChatToTheTop(chatIndex, state)
  }
}

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    updateDraftMessage: (state, action: PayloadAction<UpdateDraftMessage>) => {
      const chatIndex = ChatHelpers.findChatIndex(action.payload.chatJid, state)

      state[chatIndex].draftMessage = action.payload.draftMessage

      if (chatIndex !== 0 && action.payload.draftMessage.length > 0) {
        moveChatToTheTop(chatIndex, state)
      }
    },
    addOrUpdateParticipant: (
      state,
      action: PayloadAction<AddParticipantAction>,
    ) => {
      const chatIndex = state.findIndex(
        (chat) => chat.jid === action.payload.chatJid,
      )

      if (chatIndex === -1)
        throw `Action addDriver: can not find chat with jid: ${action.payload.chatJid}`

      if (!state[chatIndex].participants) state[chatIndex].participants = []

      const participantIndex = state[chatIndex].participants.findIndex(
        (part) => part.jid === action.payload.participant.jid,
      )

      if (participantIndex === -1) {
        state[chatIndex].participants.push(action.payload.participant)
      } else {
        state[chatIndex].participants[participantIndex] = {
          ...state[chatIndex].participants[participantIndex],
          ...action.payload.participant,
        }
      }
    },
    changeActiveChat: (
      state,
      action: PayloadAction<ChangeActiveChatAction>,
    ) => {
      const chatIndex = ChatHelpers.findChatIndex(
        action.payload.activeChatJid,
        state,
      )

      if (chatIndex === -1)
        throw `Can not find chat with jid: ${action.payload.activeChatJid}`

      state.map((chat) => {
        chat.active = false
        return chat
      })

      state[chatIndex].active = true
    },
    upsertChat: (state, action: PayloadAction<UpsertChatAction>) => {
      const index = ChatHelpers.findChatIndex(action.payload.chat.jid, state)
      if (index === -1) {
        state.unshift(buildChat(action.payload.chat))
      } else {
        state[index] = { ...state[index], ...action.payload.chat }
      }
    },
    refreshChats: (state, action: PayloadAction<RefreshChatsAction>) => {
      const chatsThatExist = state.filter((chat) => {
        return ChatHelpers.findChatIndex(chat.jid, action.payload.chats) !== -1
      })

      state.splice(0, state.length)
      state.push(...chatsThatExist)

      const addNew = action.payload.chats.filter((chat) => {
        return ChatHelpers.findChatIndex(chat.jid, state) === -1
      })

      state.push(...addNew)

      for (const chat of action.payload.chats) {
        const index = ChatHelpers.findChatIndex(chat.jid, state)
        state[index] = {
          ...state[index],
          name: chat.name,
          groups: chat.groups ?? state[index].groups,
        }
      }
    },
    limitChatSize: (state, action: PayloadAction<LimitChatSizeAction>) => {
      const jid = action.payload.jid

      Sentry.startSpan(
        { name: "limitChatSize", op: "redux.slice", attributes: { jid } },
        (span) => {
          const index = ChatHelpers.findChatIndex(jid, state)
          if (index === -1) return

          if (span) {
            span.attributes.sizeWas = state[index].messages.length
          }

          if (action.payload.limit === 0) {
            state[index].messages = []
          }

          if (state[index].messages.length > action.payload.limit) {
            state[index].messages = state[index].messages.slice(
              -action.payload.limit,
            )
          }
          if (span) {
            span.attributes.sizeIs = state[index].messages.length
          }
        },
      )
    },
    removeChat: (state, actions: PayloadAction<RemoveChatAction>) => {
      const chats = state.filter((chat) => {
        return chat.jid !== actions.payload.jid
      })
      state.splice(0, state.length)
      state.push(...chats)
    },
    addBatchMessagesToChat: (
      state,
      action: PayloadAction<AddBatchMessagesToChatAction>,
    ) => {
      const groupedByChat = action.payload.messages.reduce(
        (acc, message) => {
          const chatIndex = ChatHelpers.findMessageChatIndex(
            message,
            state,
            action.payload.myJid,
          )

          if (chatIndex != -1) {
            if (acc[chatIndex] === undefined) acc[chatIndex] = [] as Message[]

            acc[chatIndex].push(message)
          }

          return acc
        },
        {} as { [key: number]: Message[] },
      )

      for (const [chatIndex, messages] of Object.entries(groupedByChat)) {
        const chatIndexNumber = parseInt(chatIndex)

        for (const message of messages) {
          const index = state[chatIndexNumber].messages.findIndex((m) =>
            isSameMessage(message, m),
          )
          if (index !== -1) {
            state[chatIndexNumber].messages[index] = message
          }
        }

        // dedupe messages
        const filteredMessages = messages.filter((message) => {
          return (
            state[chatIndexNumber].messages.findIndex(
              (m) => m.id === message.id,
            ) === -1
          )
        })

        // Attach reactions to newly encountered messages
        for (const message of filteredMessages) {
          const reactions = state[chatIndexNumber].reactions[message.id]

          if (reactions) {
            delete state[chatIndexNumber].reactions[message.id]
            message.reactions.push(...Object.values(reactions))
          }
        }

        if (action.payload.mode === "prepend") {
          state[chatIndexNumber].messages.unshift(...filteredMessages)
        } else {
          state[chatIndexNumber].messages.push(...filteredMessages)
        }

        state[chatIndexNumber].messages.sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        )
      }
    },
    addMessageCorrection: (
      state,
      action: PayloadAction<AddMessageCorrectionAction>,
    ) => {
      updateStateUsingCorrection(
        state,
        action.payload.message,
        action.payload.myJid,
      )
    },
    multiAddMessageCorrection: (
      state,
      action: PayloadAction<BatchAddMessageCorrectionAction>,
    ) => {
      action.payload.corrections.forEach((correction) => {
        updateStateUsingCorrection(state, correction, action.payload.myJid)
      })
    },
    updateChatConnectedStatus: (
      state,
      action: PayloadAction<{ jid: string; status: ChatConnectionStatus }>,
    ) => {
      const chat = state.find((chat) => chat.jid === action.payload.jid)
      if (!chat) return
      chat.connectionStatus = action.payload.status
    },
    addMessage: (state, action: PayloadAction<AddMessageAction>) => {
      const chatIndex = ChatHelpers.findMessageChatIndex(
        action.payload.message,
        state,
        action.payload.myJid,
      )

      if (chatIndex === -1) {
        console.error(
          "Can not find a chat message corresponds to: ",
          action.payload.message,
        )
        return
      }

      // There maybe situations when server resend message to the client for ex. due to stream connection resumed
      // in such cases it is client's responsibility to make sure there are no duplicates
      // so we check first if we already have a message with such id
      const messageIndex = state[chatIndex].messages.findIndex((message) =>
        isSameMessage(message, action.payload.message),
      )

      if (messageIndex !== -1) {
        // Found message already in store, overwrite with what we are given
        // We overwrite because what we have in store might be a fake message
        // added there on button send click, and we want to store whatever the
        // server send since it is source of truth.
        state[chatIndex].messages[messageIndex] = action.payload.message
        return
      }

      if (action.payload.mode === "prepend") {
        state[chatIndex].messages.unshift(action.payload.message)
      } else {
        state[chatIndex].messages.push(action.payload.message)
      }

      if (chatIndex !== 0 && action.payload.mode !== "prepend") {
        moveChatToTheTop(chatIndex, state)
      }
    },
    markMessage: (state, action: PayloadAction<MarkMessageAction>) => {
      updateStateUsingMarker(state, action.payload.marker, action.payload.myJid)
    },
    multiMarkMessage: (
      state,
      action: PayloadAction<BatchMarkMessageAction>,
    ) => {
      action.payload.markers.forEach((marker) => {
        updateStateUsingMarker(state, marker, action.payload.myJid)
      })
    },
    addBatchMessageReactions: (
      state,
      action: PayloadAction<{ reactions: Reaction[]; myJid: string }>,
    ) => {
      const chatsWithReactions = Object.entries(
        groupBy(action.payload.reactions, (reaction) => reaction.from),
      ).map(([chatJid, reactions]) => {
        const reactionChatJid = jid.parse(chatJid).bare().toString()
        const chat = state.find((chat) => chat.jid === reactionChatJid)
        if (!chat)
          throw new Error(`Cannot find corresponding chat for jid ${chatJid}`)

        return { chat, reactions }
      })

      for (const { chat, reactions } of chatsWithReactions) {
        for (const reaction of reactions) {
          if (!chat.reactions[reaction.messageId])
            chat.reactions[reaction.messageId] = {}
          chat.reactions[reaction.messageId][reaction.from] = reaction

          handleChatEvent(state, chat, reaction, action.payload.myJid)
        }

        for (const message of chat.messages) {
          const reactions = chat.reactions[message.id]

          if (reactions) {
            delete chat.reactions[message.id]

            const userJids = Object.keys(reactions)
            message.reactions = message.reactions.filter(
              (reaction) => !userJids.includes(reaction.from),
            )
            message.reactions.push(...Object.values(reactions))
          }
        }
      }
    },
    addBatchMessageFileShares: (
      state,
      action: PayloadAction<{ messageFileShares: MessageFileShared[] }>,
    ) => {
      const chatsWithShares = Object.entries(
        groupBy(action.payload.messageFileShares, (share) => share.from),
      ).map(([chatJid, shares]) => {
        const shareChatJid = jid.parse(chatJid).bare().toString()
        const chat = state.find((chat) => chat.jid === shareChatJid)
        if (!chat)
          throw new Error(`Cannot find corresponding chat for jid ${chatJid}`)

        return { chat, shares }
      })

      for (const { chat, shares } of chatsWithShares) {
        for (const share of shares) {
          if (!chat.messageIdsWithFileShared) {
            chat.messageIdsWithFileShared = {}
          }

          chat.messageIdsWithFileShared[share.messageId] = share
        }
      }
    },
    addBatchMessageRetractions: (
      state,
      action: PayloadAction<{ messageRetractions: MessageRetraction[] }>,
    ) => {
      const chatsWithRetractions = Object.entries(
        groupBy(
          action.payload.messageRetractions,
          (retraction) => retraction.from,
        ),
      ).map(([chatJid, retractions]) => {
        const chatIndex = ChatHelpers.findChatIndex(
          jid.parse(chatJid).bare().toString(),
          state,
        )
        if (chatIndex === -1) {
          throw new Error(`Cannot find corresponding chat for jid ${chatJid}`)
        }

        return { chat: state[chatIndex], retractions }
      })

      for (const { chat, retractions } of chatsWithRetractions) {
        for (const retraction of retractions) {
          if (!chat.messageRetractions) {
            chat.messageRetractions = {}
          }

          chat.messageRetractions[retraction.messageId] = retraction
        }
      }
    },
    addMessageRetraction: (
      state,
      action: PayloadAction<AddMessageRetractionAction>,
    ) => {
      const chatIndex = ChatHelpers.findMessageChatIndex(
        action.payload.retraction,
        state,
        action.payload.myJid,
      )

      if (chatIndex === -1) {
        console.error(
          "Can not find a chat marker corresponds to: ",
          action.payload.retraction,
        )
        return
      }

      state[chatIndex].messageRetractions[action.payload.retraction.messageId] =
        action.payload.retraction
    },
    sortChatsByLatestMessageTimestamp: (state) => {
      state.sort((a, b) => {
        const aLastChatEvent = ChatHelpers.getLastChatEvent(a)
        const bLastChatEvent = ChatHelpers.getLastChatEvent(b)
        const aTimestamp = aLastChatEvent
          ? Date.parse(aLastChatEvent.timestamp)
          : null
        const bTimestamp = bLastChatEvent
          ? Date.parse(bLastChatEvent.timestamp)
          : null

        if (aTimestamp === null && bTimestamp === null) {
          return 0
        } else if (aTimestamp === null) {
          return 1
        } else if (bTimestamp === null) {
          return -1
        } else {
          return bTimestamp - aTimestamp
        }
      })
    },
    updateChatName: (state, action: PayloadAction<UpdateChatNameAction>) => {
      const chatIndex = ChatHelpers.findChatIndex(action.payload.chatJid, state)
      if (chatIndex === -1) return

      state[chatIndex].name = action.payload.name
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

const isMarked = (
  markers: Marker[],
  whom: string,
  name: Marker["name"],
): boolean => {
  return markers.some((marker) => marker.from === whom && marker.name === name)
}

const filterMakers = (
  markers: Marker[],
  whom: string,
  name: Set<Marker["name"]>,
): Marker[] => {
  return markers.filter(
    (marker) =>
      marker.from !== whom || (marker.from === whom && !name.has(marker.name)),
  )
}

export const compactMarkers = (chat: Chat, whom: string) => {
  const cleanableTypes = new Set<Marker["name"]>()
  for (let i = chat.messages.length - 1; i >= 0; i--) {
    const message = chat.messages[i]

    message.markers = filterMakers(message.markers, whom, cleanableTypes)

    if (
      !cleanableTypes.has("displayed") &&
      isMarked(message.markers, whom, "displayed")
    ) {
      cleanableTypes.add("received")
      // we can remove all received markers from current message, since we have the displayed
      message.markers = filterMakers(message.markers, whom, cleanableTypes)
      cleanableTypes.add("displayed")
    }
    if (
      !cleanableTypes.has("received") &&
      isMarked(message.markers, whom, "received")
    ) {
      cleanableTypes.add("received")
    }
  }
}

export const {
  addOrUpdateParticipant,
  changeActiveChat,
  upsertChat,
  addMessage,
  markMessage,
  multiMarkMessage,
  sortChatsByLatestMessageTimestamp,
  refreshChats,
  limitChatSize,
  removeChat,
  updateDraftMessage,
  addBatchMessagesToChat,
  addMessageCorrection,
  multiAddMessageCorrection,
  addBatchMessageReactions,
  updateChatConnectedStatus,
  addBatchMessageFileShares,
  addMessageRetraction,
  addBatchMessageRetractions,
  updateChatName,
} = chatsSlice.actions
export default chatsSlice.reducer
